import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    withRouter,
    GLForm,
    GLFormComponentProps,
    connect,
    MessageHelper,
    NotificationType,
    GLUtil
} from "gl-commonui";
import { SecondaryTitle, SubmitBtns } from "@app/components";
import { SchoolLocale } from "@app/locales/localeid";
import { Icon, Table, Select, InputNumber, Spin, Tooltip } from "antd-min";
import { fmtMsg } from "@app/util/func";
import { lazyInject, TYPES } from "@app/util";
import { SchoolClassService } from "@app/service/class";
import { StateType } from "@app/states";
import { setFutureAnnualOuter } from "@app/states/school/class";
import moment from "moment";
import { PathConfig } from "@app/config/pathconfig";
import "./groupmanager.less";
declare module "antd/lib/select" {
    export interface OptionProps {
        label: string;
    }
}

interface GroupManagerPageProps {
    list;
    setFutureAnnualOuter: (d) => void;
}

interface Student {
    studentId: string;
    studentName: string;
    nativeName: string;
    groupName: string;
    groupId: string;
    isActivated: boolean;
}

interface GroupManagerPageStates {
    deleteGroupIds: string[];
    studentsHaveNoGroup: string[];
    groups: {
        id?: string;
        name: string;
        times: string[];
        studentIds?: string[];
    }[];
    students: Student[];
    schedules: {
        id: string;
        day: any;
        daysOfWeek: number;
        duration: number;
        start: string;
        end: string;
        type: number;
    }[];
    loading: boolean;
    canSubmit: boolean;
    listGroupDuplicate: string[];
}

@withRouter
@connect(({ schoolClass: { students } }: StateType) => ({
    list: students
}), { setFutureAnnualOuter })
@GLForm.create()
export class GroupManagerPage extends Component<
    GroupManagerPageProps & RouteComponentProps<any> & GLFormComponentProps,
    GroupManagerPageStates
> {
    @lazyInject(TYPES.ISchoolClassService)
    schoolClass: SchoolClassService;

    constructor(props) {
        super(props);
        this.state = {
            deleteGroupIds: [],
            studentsHaveNoGroup: [],
            groups: [],
            students: [],
            schedules: [],
            loading: false,
            canSubmit: false,
            listGroupDuplicate: [],
        };
    }

    componentWillMount() {
        if (!this.props.match.params.classId) {
            return;
        }

        const {
            match: { params }
        } = this.props;
        //It will obtain the class information that has been used in the breadcrumb.
        this.props.setFutureAnnualOuter(params);
        
        this.schoolClass
            .getStudentGroupManager(this.props.match.params.classId)
            .then(response => { this.setState({ students: response }) });
        this.schoolClass
            .getTimeSlotGroupManager(this.props.match.params.classId)
            .then(response => {
                const newGroup = response.groups.map(group => {
                    group.studentIds = [];
                    return group;
                });
                this.setState({
                    groups: newGroup,
                    schedules: response.schedules.sort((classTimeA, classTimeB) => {
                        const daysOfWeek = classTimeA.daysOfWeek - classTimeB.daysOfWeek;
                        const classTimeAStart = classTimeA.start ? classTimeA.start : "";
                        const classTimeBStart = classTimeB.start ? classTimeB.start : "";
                        const start = classTimeAStart.localeCompare(classTimeBStart);
                        const classTimeAEnd = classTimeA.end ? classTimeA.end : "";
                        const classTimeBEnd = classTimeB.end ? classTimeB.end : "";
                        const end = classTimeAEnd.localeCompare(classTimeBEnd);
                        return daysOfWeek == 0
                            ? start == 0
                                ? end
                                : start
                            : daysOfWeek;
                    })
                });
            });
    }

    addGroup() {
        const name = `${this.state.groups.length > 0
                ? this.getNextNumber().toString()
                : 1
            }`;
        this.setState({
            canSubmit: true,
            groups: [
                ...this.state.groups,
                { name: name, times: [], studentIds: [] }
            ]
        });
    }

    getNextNumber() {
        let max = 0;
        const newGroup = this.state.groups;
        newGroup.forEach((val) => {
            if (parseInt(val.name) > max) {
                max = parseInt(val.name);
            }
        });
        return max + 1;
    }

    deleteGroup(data: any, index: number) {
        const newDeleteGroupIds = this.state.deleteGroupIds;
        const groupDelete = this.state.groups.filter((_group, i) => {
            return i == index;
        })[0];
        const newStudents = this.state.students.map(student => {
            if (student.groupName == groupDelete.name) {
                student.groupName = null;
            }
            return student;
        });
        const newGroup = this.state.groups.filter((_group, i) => {
            return i != index;
        });
        if (data.id) {
            newDeleteGroupIds.push(data.id);
        }
        this.setState({
            canSubmit: true,
            groups: newGroup,
            students: newStudents,
            deleteGroupIds: newDeleteGroupIds
        });
    }

    removeGroup(studentId: string) {
        const newStudents = this.state.students.map(student => {
            if (student.studentId == studentId) {
                student.groupName = null;
            }
            return student;
        });
        const newStudentsHaveNoGroup = this.state.studentsHaveNoGroup.includes(studentId) ?
            this.state.studentsHaveNoGroup : [...this.state.studentsHaveNoGroup, studentId];
        this.setState({
            canSubmit: true,
            students: newStudents,
            studentsHaveNoGroup: newStudentsHaveNoGroup
        });
    }

    setGroup(studentId: string, groupName: string) {
        const newStudents = this.state.students.map(student => {
            if (student.studentId == studentId) {
                student.groupName = groupName;
            }
            return student;
        });
        const newStudentHaveNoGroup = this.state.studentsHaveNoGroup.filter(id => { return id != studentId });
        this.setState({
            canSubmit: true,
            students: newStudents,
            studentsHaveNoGroup: newStudentHaveNoGroup,
        });
    }

    validateName() {
        let listGroupExist = []
        this.state.groups.map(group => {
            const groupExist = this.state.groups.filter(g => { return g.name == group.name });
            if (groupExist.length > 1) {
                listGroupExist = [...listGroupExist, groupExist[0].name]
            }
        })
        return listGroupExist;
    }

    validateNameDuplicated(value) {
        let listGroupExist = []
        const newGroup = this.state.groups;
        newGroup.forEach((val) => {
            if (val.name === value.toString()) {
                listGroupExist.push(val.name);
            }
        });
        return listGroupExist;
    }

    inValidData(groups) {
        const groupInvalidStudent = groups.filter(group => { return group.studentIds.length > 12 });
        const groupInvalidTimeSlot = groups.filter(group => { return group.times.length <= 0 });
        const groupInvalidName = this.validateName();
        if (groupInvalidName.length > 0) {
            MessageHelper.Message(NotificationType.Failed, 'Group name invalid');
            this.setState({
                listGroupDuplicate: groupInvalidName,
                canSubmit: false,
                loading: false
            });
            return true;
        } else if (groupInvalidStudent.length > 0) {
            MessageHelper.Message(NotificationType.Failed,
                `${fmtMsg({ id: SchoolLocale.GroupManagerGroup })} ${groupInvalidStudent[0].name}${fmtMsg({ id: SchoolLocale.GroupManagerValidateStudent })}`);
            this.setState({
                canSubmit: false,
                loading: false
            });
            return true;
        } else {
            return false;
        }
    }

    handleBlur() {
        this.setState({
            listGroupDuplicate: [],
            canSubmit: true,
        });
    }

    handleMultiSelect(value, index) {
        const newGroup = this.state.groups;
        newGroup[index].times = value;
        this.setState({
            canSubmit: true,
            groups: newGroup
        });
    }

    getColumns() {
        const { Option } = Select;

        return [
            {
                title: fmtMsg({ id: SchoolLocale.GroupManagerGroupName }),
                width: "35%",
                render: (_text, data, index) => {
                    return (
                        <div className="group-row-table-holder">
                            <span>{fmtMsg({ id: SchoolLocale.GroupManagerGroup })}</span>
                            <span className="ml-5">{'- ' + data.name}</span>
                        </div>
                    );
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.GroupManagerTimeSlot }),
                width: "50%",
                render: (_text, data, index) => {
                    return (
                        <div className="group-row-table-holder">
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder={fmtMsg({
                                    id: SchoolLocale.GroupManagerSelectPlaceHolder
                                })}
                                value={data.times ? data.times : []}
                                onChange={value =>
                                    this.handleMultiSelect(value, index)
                                }
                                optionLabelProp="label"
                            >
                                {this.state.schedules.map(timeSlot => {
                                    const day = moment()
                                        .day(timeSlot.daysOfWeek - 1)
                                        .format("ddd");
                                    const timeStart = timeSlot.start ? timeSlot.start.split(":") : [];
                                    const timeEnd = timeSlot.end ? timeSlot.end.split(":") : [];
                                    let label = `${day}`;
                                    if (timeStart.length > 0 && timeEnd.length > 0) {
                                        label = label + `: ${timeStart[0]}:${timeStart[1]} - ${timeEnd[0]}:${timeEnd[1]}`;
                                    }
                                    return (
                                        <Option value={timeSlot.id} label={label}>
                                            {label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    );
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.GroupManagerDelete }),
                width: "15%",
                render: (_text, data, index) => {
                    return (
                        <div className="group-row-table-holder">
                            <Icon
                                type="close"
                                onClick={() => this.deleteGroup(data, index)}
                            />
                        </div>
                    );
                }
            }
        ];
    }

    createData(groups, deleteGroupIds, studentsHaveNoGroup) {
        const groupWithStudentIds = groups.map(group => {
            group.studentIds = this.state.students.filter(s => s.groupName == group.name).map(student => { return student.studentId });
            return group;
        });
        return {
            deletedGroupIds: deleteGroupIds,
            studentsHaveNoGroup: studentsHaveNoGroup,
            groups: groupWithStudentIds
        };
    }

    onBack() {
        const path = GLUtil.pathStringify(PathConfig.Students, {
            regionId: this.props.match.params.regionId,
            schoolId: this.props.match.params.schoolId,
            campusId: this.props.match.params.campusId,
            classId: this.props.match.params.classId,
        });
        this.props.history.push(path);
    }

    onSubmit(e, groups, deleteGroupIds, studentsHaveNoGroup) {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const data = this.createData(groups, deleteGroupIds, studentsHaveNoGroup);
        if (this.inValidData(data.groups)) {
            return;
        }
        this.schoolClass
            .updateGroupManager(this.props.match.params.classId, data)
            .then(response => {
                if (response) {
                    this.schoolClass
                        .getStudentGroupManager(this.props.match.params.classId)
                        .then(response => { this.setState({ students: response }) });
                    this.schoolClass
                        .getTimeSlotGroupManager(this.props.match.params.classId)
                        .then(response => {
                            const newGroup = response.groups.map(group => {
                                group.studentIds = [];
                                return group;
                            });
                            this.setState({
                                groups: newGroup,
                                schedules: response.schedules
                            });
                        });
                    this.onBack();
                } else {
                    MessageHelper.Message(
                        NotificationType.Failed,
                        fmtMsg({ id: SchoolLocale.GroupManagerMessageFail })
                    );
                }
                this.setState({
                    loading: false
                });
            }).catch(er => {
                const message = JSON.parse(er.text);
                MessageHelper.Message(
                    NotificationType.Failed,
                    message.error_description
                );
                this.setState({
                    loading: false
                });
            });
    }

    renderStudentItem(student: Student) {
        return (
            <div key={student.studentId} className="list-student__item">
                <Tooltip title={student.isActivated ? null : fmtMsg({ id: SchoolLocale.GroupManagerUnregisteredStudent })}>
                    <div className={student.isActivated ? "list-student__title" : "list-student__title inactive"}>
                        <span className="engName">{student.studentName}</span>
                        <span className="nativeName">{`(${student.nativeName})`}</span>
                    </div>
                </Tooltip>
                <div className="list-student__group">
                    <span key={student.studentName}
                        className={!student.groupName ? "list-student__group-item active" : "list-student__group-item"}
                        onClick={() => this.removeGroup(student.studentId)}>
                        {fmtMsg({ id: SchoolLocale.GroupManagerNone })}
                    </span>
                    {this.state.groups.map((group, i) => {
                        return (
                            <span key={student.studentName + i}
                                className={student.groupName && group.name == student.groupName ? "list-student__group-item active" : "list-student__group-item"}
                                onClick={() => this.setGroup(student.studentId, group.name)}>
                                {group.name}
                            </span>
                        );
                    }
                    )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="group-page">
                <Spin spinning={this.state.loading}>
                    <GLForm onSubmit={e => this.onSubmit(e, this.state.groups, this.state.deleteGroupIds, this.state.studentsHaveNoGroup)}>
                        <SecondaryTitle title={SchoolLocale.GroupManagerPageTitle}></SecondaryTitle>
                        <div className="create-group">
                            <a onClick={() => this.addGroup()}>{fmtMsg({ id: SchoolLocale.GroupManagerAddGroup })}</a>
                        </div>
                        <div className={this.state.groups.length > 0 ? "list-group" : "no-group"}>
                            {this.state.groups.length > 0 ? (
                                <Table pagination={false} dataSource={this.state.groups} columns={this.getColumns()} tableLayout={'auto'} />
                            ) : (
                                <span className="no-group-message">
                                    {fmtMsg({ id: SchoolLocale.GroupManagerNoGroupMessage1 })}
                                    <a onClick={() => this.addGroup()}>{fmtMsg({ id: SchoolLocale.GroupManagerCreate })}</a>
                                    {fmtMsg({ id: SchoolLocale.GroupManagerNoGroupMessage2 })}
                                </span>
                            )}
                        </div>
                        <div className="list-student">
                            <div className="group-student">
                                {this.state.students.slice(0, Math.round(this.state.students.length / 2)).map(student => this.renderStudentItem(student))}
                            </div>
                            <div className="group-student">
                                {this.state.students.slice(Math.round(this.state.students.length / 2), this.state.students.length).map(student => this.renderStudentItem(student))}
                            </div>
                        </div>
                        <SubmitBtns
                            isDisabled={!this.state.canSubmit}
                            submitTitle={SchoolLocale.GroupManagerSave}
                            cancelTitle={SchoolLocale.GroupManagerCancel}
                            onCancel={() => this.onBack()}
                        />
                    </GLForm>
                </Spin>
            </div>
        );
    }
}
